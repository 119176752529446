import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api';

export const breakpoints = {
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
};

const containerElement = ref<HTMLDivElement>();
const containerWidth = ref<number>(0);

export const useResponsiveBreakpoints = () => {
  const isSm = computed(() => containerWidth.value >= breakpoints.sm);
  const isMd = computed(() => containerWidth.value >= breakpoints.md);
  const isLg = computed(() => containerWidth.value >= breakpoints.lg);
  const isXl = computed(() => containerWidth.value >= breakpoints.xl);

  return {
    isLg,
    isMd,
    isSm,
    isXl,
  };
};

export const useResponsiveDesign = () => {
  const handleResize = () => {
    if (containerElement.value) {
      containerWidth.value = containerElement.value.clientWidth;
    }
  };

  onMounted(() => {
    window.addEventListener('resize', handleResize);
    setTimeout(handleResize, 100);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  return {
    containerElement,
  };
};
