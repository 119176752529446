




import { defineComponent } from '@vue/composition-api';
import GcAlert from '@/components/primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
  },
});
