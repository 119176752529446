



























import { defineComponent } from '@vue/composition-api';
import { faCompass } from '@fortawesome/pro-solid-svg-icons';
import GcImage from '@/components/primitives/GcImage.vue';
import GcButton from '@/components/primitives/GcButton.vue';

export default defineComponent({
  components: {
    GcButton,
    GcImage,
  },
  props: {
    started: {
      default: false,
      type: Boolean,
    },
  },
  setup: (_, { emit }) => {
    const baseUrl = process.env.VUE_APP_BASE_URL;

    const toggleAssistant = () => {
      emit('toggle');
    };

    return {
      baseUrl,
      faCompass,
      toggleAssistant,
    };
  },
});
