























































































import { VInput } from 'vuetify/lib';
import { computed, ref } from '@vue/composition-api';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFilePlus,
  faUpload,
} from '@fortawesome/pro-duotone-svg-icons';
import GcDivider from '@/components/primitives/GcDivider.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import i18n from '@/plugins/i18n';

const getSizeInMB = (sizeInBytes: number): string =>
  (sizeInBytes / 1024 ** 2).toLocaleString('de-DE', {
    maximumFractionDigits: 1,
  });

export default VInput.extend({
  components: {
    GcAlert,
    GcButton,
    GcDivider,
  },
  props: {
    accept: {
      default: () => '',
      type: String,
    },
    maxFileSizeInBytes: {
      default: false,
      type: [Boolean, Number],
    },
    multiple: {
      default: false,
      type: Boolean,
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup: (props: any, context) => {
    const fileInput = ref<HTMLInputElement | null>(null);
    const dragOver = ref(false);
    const errMsg = ref('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addFile = (e: any) => {
      let newFiles = e.dataTransfer?.files || e.target.files;

      if (!props.multiple && newFiles.length > 1) {
        newFiles = [newFiles[0]];
      }

      if (props.maxFileSizeInBytes) {
        const tooLargeFiles = [...newFiles].filter(
          (file) => file.size > props.maxFileSizeInBytes,
        );
        if (tooLargeFiles.length) {
          let text = (i18n.tc(
            'upload.fileSizeExceededWarning',
            tooLargeFiles.length,
            { size: getSizeInMB(props.maxFileSizeInBytes) },
          ) + '<br>') as string;

          text += tooLargeFiles
            .map((file) => `${file.name} (${getSizeInMB(file.size)} MB)`)
            .join('<br>');
          errMsg.value = text;
          return;
        }
      }

      if (props.accept) {
        const acceptMimeTypes = props.accept
          .split(',')
          .map((mimeType) => mimeType.trim());
        const invalidMimeTypes = [...newFiles].filter(
          (file) =>
            !acceptMimeTypes.includes(file.type) &&
            !acceptMimeTypes.includes(
              file.type.replace(/^([^/]+\/).+$/, '$1*'),
            ),
        );

        if (invalidMimeTypes.length) {
          let text = (i18n.tc(
            'upload.mimeTypeWarning',
            invalidMimeTypes.length,
          ) + '<br>') as string;

          text += invalidMimeTypes.map((file) => file.name).join('<br>');
          errMsg.value = text;
          return;
        }
      }

      if (newFiles.length) {
        const emittedValue = props.multiple
          ? [...props.value, ...newFiles]
          : [...newFiles];
        context.emit('input', emittedValue);
        errMsg.value = '';
      }
    };
    const removeFile = (fileIndex: number) => {
      if (props.value[fileIndex]) {
        const updatedArray = [...props.value];
        updatedArray.splice(fileIndex, 1);
        context.emit('input', updatedArray);
      }
    };
    const openFileChooser = () => {
      fileInput.value?.click();
      errMsg.value = '';
    };
    const onDrop = (e: DragEvent) => {
      dragOver.value = false;
      addFile(e);
    };
    const onDragOver = () => {
      dragOver.value = true;
    };
    const onDragLeave = () => {
      dragOver.value = false;
    };

    const getFileIcon = (file: File) => {
      if (file.type?.includes('pdf')) {
        return faFilePdf;
      } else if (file.type?.includes('image/')) {
        return faFileImage;
      } else {
        return faFileAlt;
      }
    };

    const isMobile = computed(
      () =>
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) && navigator.mediaDevices.enumerateDevices,
    );

    return {
      addFile,
      dragOver,
      errMsg,
      faFilePlus,
      faTimes,
      faUpload,
      fileInput,
      getFileIcon,
      isMobile,
      onDragLeave,
      onDragOver,
      onDrop,
      openFileChooser,
      removeFile,
    };
  },
});
