

























import { defineComponent } from '@vue/composition-api';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import GcButton from '@/components/primitives/GcButton.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
  },
  props: {
    hasPersistedState: {
      default: false,
      type: Boolean,
    },
  },
  setup: () => ({
    faAngleRight,
  }),
});
