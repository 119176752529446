const rangeDivider = ' bis ';

export const formatDate = (date: string): string => {
  const dateParts = date.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  return `${day}.${month}.${year}`;
};

export const formatDateRange = (dates: Array<string>): string => {
  if (!dates) {
    return '';
  }

  const formattedDates = dates.map((date: string) => formatDate(date));

  if (formattedDates.length === 1) {
    return `${formattedDates[0]}` + rangeDivider;
  } else if (formattedDates.length > 1) {
    return formattedDates.join(rangeDivider);
  }

  return '';
};

export const parseDate = (dateString: string): string | null => {
  if (!dateString) {
    return null;
  }
  const valueParts = dateString.split('.');
  const day = valueParts[0];
  const month = valueParts[1];
  const year = valueParts[2];

  return `${year}-${month}-${day}`;
};

export const parseDates = (
  date: string,
  range = false,
): string | string[] | null => {
  if (!date) {
    return null;
  }

  const valueParts = date.split(rangeDivider);

  return range
    ? valueParts
        .filter((part) => part.trim().length > 0)
        .map((part) => parseDate(part) || '')
    : parseDate(valueParts[0]);
};

export const addDays = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString().split('T')[0];
};
