










import { defineComponent } from '@vue/composition-api';
import AssistantContainer from '@/components/AssistantContainer.vue';
import { useResponsiveDesign } from '@/lib/ui-helper/responsive';
import { useAssistantMachine } from '@/lib/xstate/machine';
import steps from '~/steps/steps';

export default defineComponent({
  components: { AssistantContainer },
  setup: () => {
    const { containerElement } = useResponsiveDesign();
    const elementId = process.env.VUE_APP_SITE_NAME;

    if (!elementId) {
      throw Error('No element id defined');
    }

    const machine = useAssistantMachine(steps);

    return {
      containerElement,
      elementId,
      machine,
      steps,
    };
  },
});
