














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup: (_, { emit }) => {
    const onClickSkip = () => {
      emit('skip');
    };

    return { onClickSkip };
  },
});
