import { ValidationType } from '@/consts/validation-types';
import { FormFieldType } from '@/consts/input-types';
import {
  FormAlertComponentConfig,
  FormCheckboxComponentConfig,
  FormCheckboxValueType,
  FormDatePickerComponentConfig,
  FormDatePickerValueType,
  FormField,
  FormFieldComponentConfig,
  FormFieldValidation,
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  FormSelectComponentConfig,
  FormSelectValueType,
  FormSliderComponentConfig,
  FormSliderValueType,
  FormSwitchComponentConfig,
  FormSwitchValueType,
  FormTextAreaComponentConfig,
  FormTextAreaValueType,
  FormTextFieldComponentConfig,
  FormTextFieldValueType,
  FormTimePickerComponentConfig,
  FormTimePickerValueType,
  FormUploadFieldComponentConfig,
  FormUploadFieldValueType,
} from '@/interfaces/step-definition';

const buildFormFieldFactory = <
  Config extends FormFieldComponentConfig = {},
  Value = never
>(
  type: FormFieldType,
) => (
  name: string,
  options: Omit<FormField<Value>, 'name' | 'type' | 'component'> & {
    component?: Config;
  } = {},
): FormField<Value> => ({
  name,
  type,
  ...options,
  validation: (options?.required === true
    ? ([ValidationType.required] as Array<FormFieldValidation<Value>>)
    : []
  ).concat(options?.validation || []),
});

export const buildFormTextField = buildFormFieldFactory<
  FormTextFieldComponentConfig,
  FormTextFieldValueType
>(FormFieldType.TextField);

export const buildFormTextArea = buildFormFieldFactory<
  FormTextAreaComponentConfig,
  FormTextAreaValueType
>(FormFieldType.TextArea);

export const buildFormSelect = buildFormFieldFactory<
  FormSelectComponentConfig,
  FormSelectValueType
>(FormFieldType.Select);

export const buildFormSwitch = buildFormFieldFactory<
  FormSwitchComponentConfig,
  FormSwitchValueType
>(FormFieldType.Switch);

export const buildFormDatePicker = buildFormFieldFactory<
  FormDatePickerComponentConfig,
  FormDatePickerValueType
>(FormFieldType.DatePicker);

export const buildFormTimePicker = buildFormFieldFactory<
  FormTimePickerComponentConfig,
  FormTimePickerValueType
>(FormFieldType.TimePicker);

export const buildFormCheckbox = buildFormFieldFactory<
  FormCheckboxComponentConfig,
  FormCheckboxValueType
>(FormFieldType.Checkbox);

export const buildFormSectionLabel = buildFormFieldFactory(
  FormFieldType.SectionLabel,
);

export const buildFormSectionTitle = buildFormFieldFactory(
  FormFieldType.SectionTitle,
);

export const buildFormAlert = buildFormFieldFactory<FormAlertComponentConfig>(
  FormFieldType.Alert,
);

export const buildFormSlider = buildFormFieldFactory<
  FormSliderComponentConfig,
  FormSliderValueType
>(FormFieldType.Slider);

export const buildFormFileUploadField = buildFormFieldFactory<
  FormUploadFieldComponentConfig,
  FormUploadFieldValueType
>(FormFieldType.FileUpload);

export const buildFormMultipleChoiceField = buildFormFieldFactory<
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType
>(FormFieldType.MultipleChoice);
