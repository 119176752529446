<script>
import { VTextarea } from 'vuetify/lib';
export default VTextarea.extend({
  props: {
    dense: {
      default: true,
      type: [Boolean, String],
    },
    outlined: {
      default: true,
      type: [Boolean, String],
    },
  },
});
</script>
