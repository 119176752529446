import { PropType } from '@vue/composition-api';
import { AssistantContext } from '~/steps/steps';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';

export const stepComponentProps = {
  assistantContext: {
    default: () => ({}),
    required: true,
    type: Object as PropType<AssistantContext>,
  },
  step: {
    required: true,
    type: Object as PropType<StepDefinition>,
  },
  steps: {
    required: true,
    type: Array as PropType<StepDefinition[]>,
  },
};

export const getStep = (
  id: string | StepCategoryId | StepId,
  steps: StepDefinition[],
) => steps.find((s) => id === s.id);

export const getStepIndex = (id: string | StepId, steps: StepDefinition[]) =>
  steps.findIndex((s) => id === s.id);

export const getStepByIndex = (index: number, steps: StepDefinition[]) =>
  steps[index];

export const getInitialStep = (steps: StepDefinition[]) =>
  (steps.find((step) => step.isInitial) || steps[0])?.id || undefined;

export const getContactFormStep = (steps: StepDefinition[]) =>
  steps.find((step) => step.type === StepTypes.CONTACT_FORM)?.id || undefined;
