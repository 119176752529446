











import { VInput, VTimePicker } from 'vuetify/lib';
import GcTextField from '@/components/primitives/GcTextField.vue';

export default VInput.extend({
  components: {
    GcTextField,
    VTimePicker,
  },
  setup: (_, { emit }) => {
    const onInput = (value: string) => {
      emit('input', value.replace(/\./, ':'));
    };

    const onClear = () => {
      emit('input', '');
    };

    return { onClear, onInput };
  },
});
