import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import {
  faCalendarAlt,
  faCalendarWeek,
  faClock,
  faMinus,
  faPencilAlt,
  faPlus,
  faSearch,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { getTenantConfig } from '@/lib/tenants';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: {
      add: {
        component: FontAwesomeIcon,
        props: {
          icon: faPlus,
        },
      },
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: faCalendarAlt,
        },
      },
      calendarRange: {
        component: FontAwesomeIcon,
        props: {
          icon: faCalendarWeek,
        },
      },
      clear: {
        component: FontAwesomeIcon,
        props: {
          icon: faTimes,
        },
      },
      clock: {
        component: FontAwesomeIcon,
        props: {
          icon: faClock,
        },
      },
      edit: {
        component: FontAwesomeIcon,
        props: {
          icon: faPencilAlt,
        },
      },
      remove: {
        component: FontAwesomeIcon,
        props: {
          icon: faMinus,
        },
      },
      search: {
        component: FontAwesomeIcon,
        props: {
          icon: faSearch,
        },
      },
    },
  },
  lang: {
    current: 'de',
    locales: { de },
  },
  theme: {
    options: { customProperties: true },
    themes: { light: getTenantConfig().styles.colors },
  },
});
