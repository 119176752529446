








































import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import GcDivider from '@/components/primitives/GcDivider.vue';
import GcStepper from '@/components/primitives/GcStepper.vue';
import GcStepperHeader from '@/components/primitives/GcStepperHeader.vue';
import GcStepperStep from '@/components/primitives/GcStepperStep.vue';
import GcProgressLinear from '@/components/primitives/GcProgressLinear.vue';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantContext } from '~/steps/steps';

export default defineComponent({
  components: {
    GcDivider,
    GcProgressLinear,
    GcStepper,
    GcStepperHeader,
    GcStepperStep,
  },
  props: {
    context: {
      required: true,
      type: Object as PropType<AssistantContext>,
    },
    currentStep: {
      required: true,
      type: Number,
    },
    done: {
      default: false,
      type: Boolean,
    },
    showLabels: {
      default: false,
      type: Boolean,
    },
    steps: {
      required: true,
      type: Array as () => Array<StepDefinition>,
    },
  },
  setup: (props, { emit }) => {
    const isDisabled = (step: StepDefinition): boolean =>
      step.isDisabled ? step.isDisabled(props.context) : false;

    const stepper = computed(() => props.currentStep);

    const currentStepCategoryId = computed(
      () => props.steps[props.currentStep - 1].categoryId,
    );

    // Only show progress-bar (percentage) if there are multiple categories
    const multipleStepCategories = computed(
      () =>
        [...new Set(props.steps.map((step: StepDefinition) => step.categoryId))]
          .length > 1,
    );

    // The first step should be 0% because nothing is done so far,
    // that's why we calculate currentStep - 1.
    // So we also subtract 1 from steps.length to reach a total of 100%
    // at the end.
    const progress = computed(
      () => ((props.currentStep - 1) / (props.steps.length - 1)) * 100,
    );

    const backToStep = (step: StepDefinition) => {
      if (isDisabled(step) || props.done) {
        return;
      }
      emit('transition', {
        event: `JUMP_TO_${step.id.toUpperCase()}`,
      });
    };

    return {
      backToStep,
      currentStepCategoryId,
      faCheck,
      isDisabled,
      multipleStepCategories,
      progress,
      stepper,
    };
  },
});
