<script>
import { VSwitch } from 'vuetify/lib';
export default VSwitch.extend({
  props: {
    inset: {
      default: true,
      type: [String, Boolean],
    },
  },
});
</script>
