

















import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    hasRequiredFields: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup: () => {
    const scrollMarker = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollMarker.value) {
        setTimeout(() => {
          const elementOffset =
            (scrollMarker.value?.getBoundingClientRect().top ?? 0) +
            (window.pageYOffset || document.documentElement.scrollTop) -
            120;
          window.scrollTo({
            behavior: 'smooth',
            top: elementOffset,
          });
        }, 100);
      }
    });

    return { scrollMarker };
  },
});
