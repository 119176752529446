/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  wallboxData: {
    manufacturerIdentifier: {
      label: 'Hersteller-Nummer/Bezeichner',
    },
    chargingPowersWatt: {
      label: 'Ladeleistungen',
    },
    electricCurrentsAmpere: {
      label: 'Stromstärken',
    },
    voltagesVolt: {
      label: 'Spannungen',
    },
    noOfPhases: {
      label: 'Phasen',
      options: {
        option1: '1-phasig',
        option2: '2-phasig',
        option3: '3-phasig',
      },
    },
    electricProtectionDevices: {
      label: 'Elektrische Schutzeinrichtungen',
      options: {
        dcProtection: 'DC Schutz',
        fiTypeAWithDcProtection: 'FI Typ A mit DC Schutz',
        fiTypeB: 'FI Typ B',
      },
    },
    protocols: {
      label: 'Protokolle',
      options: {
        ocpp: 'OCPP',
        udp: 'UDP',
        modbusTcp: 'ModbusTCP',
      },
    },
    networkConnectors: {
      label: 'Netzwerkadapter',
      options: {
        bluetooth: 'Bluetooth',
        lan: 'LAN',
        wlan: 'WLAN',
        sim: 'SIM',
      },
    },
    protectionClasses: {
      label: 'Schutzklassen',
    },
    noOfChargingPoints: {
      label: 'Anzahl der Ladepunkte',
      options: {
        option1: '1 Ladepunkt',
        option2: '2 Ladepunkte',
      },
    },
    chargingConnectorTypes: {
      label: 'Ladeanschlüsse',
      selectOptions: {
        type2cable: 'Typ 2 Ladekabel',
        type2socket: 'Typ 2 Steckdose',
      },
    },
    installationOptions: {
      label: 'Montagemöglichkeiten',
      selectOptions: {
        wallMount: 'Wandmontage',
        pillarMount: 'Standsäule',
        portable: 'Tragbar',
      },
    },
    cableLengthMeter: {
      label: 'Kabellänge',
      placeholder: 'Länge eingeben',
      fieldSuffix: 'Meter',
    },
    displayTypes: {
      label: 'Art der Statusanzeige',
      selectOptions: {
        led: 'LED',
      },
    },
    accessProtectionOptions: {
      label: 'Zugangsschutz',
      selectOptions: {
        app: 'App',
        keySwitch: 'Schlüsselschalter',
        pin: 'PIN',
        rfid: 'RFID',
      },
    },
    energyMeters: {
      label: 'Energiezähler',
      selectOptions: {
        calibrated: 'Eichrechtskonform',
        mid: 'MID',
        uncalibrated: 'Ungeeicht',
      },
    },
    loadManagement: {
      label: 'Lastmanagement',
    },
    billable: {
      label: 'Abrechnungsfähig',
    },
    billingSolution: {
      label: 'Abrechnungslösung',
    },
    smartphoneControllable: {
      label: 'Über Smartphone steuerbar',
    },
    pvCombinable: {
      label: 'Mit PV kombinierbar',
    },
    outdoorReady: {
      label: 'Geeignet für Außenbereich',
    },
    climateNeutral: {
      label: 'Klimaneutral',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
