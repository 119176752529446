import Vue from 'vue';
import '@/plugins/composition-api';
import '@/plugins/font-awesome';
import '@/plugins/qrcode-generator';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from '@/App.vue';
import '@/styles/main.scss';

Vue.config.productionTip = false;

new Vue({
  i18n,
  render: (h) => h(App),
  vuetify,
}).$mount('#' + process.env.VUE_APP_SITE_NAME);
