import { TranslateResult } from 'vue-i18n';
import i18n from '@/plugins/i18n';

/**
 * Returns true if value not empty or string with error message
 */
export const checkRequired = (val: string): boolean | TranslateResult =>
  !!val || i18n.t('validators.checkRequired');

/**
 * Returns true if valid email or string with error message
 */
export const checkEmail = (val: string): boolean | TranslateResult => {
  // eslint-disable-next-line no-control-regex
  const regExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regExp.test(val) ? true : i18n.t('validators.invalidEmail');
};

/**
 * Allowed values, e.g.
 * +49 123 4567489
 * 0123 456789
 * (0123) 456789
 * 0123 - 456789
 * 0123/456789
 *
 * Returns true if valid phone number or string with error message
 */
export const checkPhoneNumber = (val: string): boolean | TranslateResult => {
  const regExp = /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\s?[-/]?\s?\d{3,8}$/;
  return regExp.test(val) ? true : i18n.t('validators.invalidPhoneNumber');
};

/**
 * Allowed values 5 digits
 *
 * Returns true if valid postal code or string with error message
 */
export const checkPostalCodeDE = (val: string): boolean | TranslateResult => {
  const regExp = /^[0-9]{5}$/;
  return regExp.test(val) ? true : i18n.t('validators.invalidPostalCode');
};

/**
 * Allowed values only digits
 *
 * Returns true if valid or string with error message
 */
export const checkNumeric = (val: string): boolean | TranslateResult => {
  const regExp = /^[0-9]+$/;
  return regExp.test(val) ? true : i18n.t('validators.checkNumeric');
};
