import tenants from '@/config/tenant-config.json';

interface TenantConfig {
  acronym: string;
  styles: {
    colors: {
      primary: string;
      secondary?: string;
      accent?: string;
      error?: string;
      info?: string;
      success?: string;
      warning?: string;
    };
    rounded?: boolean;
  };
}

export const getTenantConfig = (): TenantConfig => {
  const acronym = process.env.VUE_APP_TENANT_ACRONYM;

  if (!acronym) {
    throw new Error(
      'Please provide a tenant acronym as an environment variable. (TENANT_ACRONYM)',
    );
  }

  const tenantConfig = tenants[acronym];

  return tenantConfig as TenantConfig;
};
