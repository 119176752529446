import axios from 'axios';
import { AssistantContext } from '~/steps/steps';
import { getFilesFromContext } from '@/lib/xstate/machine';

export const uploadFile = async (file: File): Promise<string> => {
  const filePath = Date.now() + '/' + file.name;
  try {
    const { data: signedUploadUrl } = await axios.post(
      process.env.VUE_APP_FUNCTIONS_URL + '/signedUploadUrl',
      {
        filePath,
        fileType: file.type,
      },
    );

    await axios.put(signedUploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return filePath;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error(`error uploading the file '${file.name}'`);
  }
};

export const getUploadedFiles = async (
  context: AssistantContext,
): Promise<string[]> => {
  try {
    return await Promise.all(
      getFilesFromContext(context).map(async (file) => await uploadFile(file)),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('error uploading files to s3-bucket');
  }
};
