import { ComputedRef, computed } from '@vue/composition-api';
import { AssistantContext } from '~/steps/steps';
import { FormFieldType } from '@/consts/input-types';
import { StepTypes } from '@/consts/step-types';
import { StepDefinition } from '@/interfaces/step-definition';

const getStepContextDefaultValues = (step: StepDefinition) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const values: any = {};
  step.fields?.forEach((field) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let defaultValue: any;

    switch (field.type) {
      case FormFieldType.MultipleChoice:
        defaultValue = [];
        break;
      default:
        defaultValue = '';
    }

    if (field.default) {
      defaultValue = field.default;
    }

    values[field.name] = defaultValue;
  });
  return values;
};

export const createContextFromSteps = (steps: StepDefinition[]) => {
  const context = {};

  steps.forEach((step) => {
    // Only DEFAULT/CONTACT_FORM steps contain data
    if (
      step.type &&
      ![StepTypes.DEFAULT, StepTypes.CONTACT_FORM].includes(step.type)
    ) {
      return;
    }

    context[step.id] = getStepContextDefaultValues(step);
    // @todo: Check if really needed
    // if (step.groupId) {
    //   if (!context[step.groupId]) {
    //     context[step.groupId] = [{}];
    //   }
    //
    //   if (!context[`${step.groupId}Index`]) {
    //     context[`${step.groupId}Index`] = 0;
    //   }
    //
    //   context[step.groupId][0][step.id] = getStepContextDefaultValues(step);
    // } else {
    //   context[step.id] = getStepContextDefaultValues(step);
    // }
  });

  return context as AssistantContext;
};

// @todo: Check if really needed
// export function addStepGroupItem(
//   context: AssistantContext,
//   groupId: StepGroupId | string,
//   steps: StepDefinition[],
// ) {
//   if (Array.isArray(context[groupId])) {
//     const newGroupIndex = (context[groupId] as Array<any>).push({}) - 1;
//     steps
//       .filter(step => step.groupId && step.groupId === groupId)
//       .forEach(step => {
//         (context[groupId] as Array<any>)[newGroupIndex][
//           step.id
//         ] = getStepContextDefaultValues(step);
//       });
//     (context[`${groupId}Index`] as number) += 1;
//   }
// }

// @todo: Check if really needed
// export function removeStepGroupItem(
//   context: AssistantContext,
//   groupId: StepGroupId | string,
//   index: number,
// ) {
//   (context[groupId] as Array<any>).splice(index, 1);
//   (context[`${groupId}Index`] as number) -= 1;
// }
//
// export function setStepGroupIndex(
//   context: AssistantContext,
//   groupId: StepGroupId,
//   index: number,
// ) {
//   (context[`${groupId}Index`] as number) = index;
// }

// @todo: Check if really needed
// export function getStepGroupIndex(
//   context: AssistantContext,
//   groupId: StepGroupId | string,
// ): number {
//   return context[`${groupId}Index`] as number;
// }

export const getStepContext = (
  context: AssistantContext,
  step?: StepDefinition,
  // eslint-disable-next-line
  repeatIndex?: number, // TODO: check if needed
): ComputedRef =>
  computed(() => {
    if (!step) {
      throw new Error('Step is required to get step context');
    }

    return context[step.id];
    // @todo: Check if really needed
    /*if (step.groupId) {
      const groupRepeatIndex =
        repeatIndex !== undefined
          ? repeatIndex
          : context[`${step.groupId}Index`] || 0;

      if (!context[step.groupId]?.[groupRepeatIndex]?.[step.id]) {
        return null;
      }

      return context[step.groupId][groupRepeatIndex][step.id];
    } else {
      return context[step.id];
    }*/
  });

// @todo: Check if really needed
// export function getStepsGroupContext(
//   context: AssistantContext,
//   groupId: StepGroupId,
// ) {
//   return computed(() => {
//     return context[groupId] || null;
//   });
// }

// @todo: Check if really needed
// export function getContextValue(
//   context: AssistantContext,
//   path: ContextPath = [],
// ): WritableComputedRef<any> {
//   const contextPathArray: Array<string | number> =
//     typeof path === 'function' ? path(context) : path || [];
//
//   return computed({
//     get() {
//       if (contextPathArray.length === 0) {
//         return null;
//       }
//
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       let subpath: any = context;
//       contextPathArray.forEach(key => {
//         if (!subpath) {
//           return null;
//         }
//         subpath = subpath[key];
//       });
//
//       return subpath;
//     },
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     set(value: any) {
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       let obj: any = context;
//       let i = 0;
//       while (i < contextPathArray.length - 1) {
//         obj = obj[contextPathArray[i]];
//         i++;
//       }
//       obj[contextPathArray[i]] = value;
//     },
//   });
// }
