import { NEXT, PREV, SUBMIT } from '@/consts/assistant-state-events';
import { AssistantContext } from '~/steps/steps';
import { StepDefinition } from '@/interfaces/step-definition';

export const useBaseTransitions = (
  // eslint-disable-next-line
  emit: (event: string, ...args: any[]) => void,
  assistantContext: AssistantContext,
  step?: StepDefinition,
): {
  [key: string]: () => void;
} => {
  const onNext = () => {
    if (step?.beforeNext && !step?.beforeNext(assistantContext)) {
      // If function doesn't return true we don't want any transition
      return;
    }

    emit('transition', {
      event: NEXT,
    });
  };

  const onPrev = () => {
    if (step?.beforePrev && !step?.beforePrev(assistantContext)) {
      // If function doesn't return true we don't want any transition
      return;
    }

    emit('transition', {
      event: PREV,
    });
  };

  const onSubmit = () => {
    emit('transition', {
      event: SUBMIT,
    });
  };

  return {
    onNext,
    onPrev,
    onSubmit,
  };
};
