<template>
  <gc-button icon outlined small color="info" class="gc-ml-2" @click="onClick"
    ><font-awesome-icon :icon="faQuestion"
  /></gc-button>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import GcButton from '@/components/primitives/GcButton.vue';

export default defineComponent({
  components: { GcButton },
  setup: (_, { emit }) => {
    const onClick = () => {
      emit('click');
    };

    return {
      faQuestion,
      onClick,
    };
  },
});
</script>
