import { StepId } from '~/consts/assistant-steps';
import {
  FormField,
  FormMultipleChoiceComponentConfig,
  MultipleChoiceOption,
} from '@/interfaces/step-definition';

export const getFieldOptions = (
  name: string,
  fields: FormField[],
): MultipleChoiceOption[] => {
  const field = fields.find((f) => name === f.name);
  if (!field) {
    return [];
  }

  return (field.component as FormMultipleChoiceComponentConfig).options;
};

export const getComponentLabelString = (
  stepId: StepId | string,
  fieldName: string,
) => `${stepId}.formFields.${fieldName}.component.label`;

export const getOptionLabelString = (
  stepId: StepId | string,
  fieldName: string,
  optionName: string,
) => `${stepId}.formFields.${fieldName}.options.${optionName}.label`;

export const getOptionDescriptionString = (
  stepId: StepId | string,
  fieldName: string,
  optionName: string,
) => `${stepId}.formFields.${fieldName}.options.${optionName}.description`;
