import axios from 'axios';
import { getUploadedFiles } from './upload-files';
import { AssistantContext } from '~/steps/steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { getMarkdownData } from '@/lib/formatter/context';

interface AdditionalData {
  // eslint-disable-next-line
  [key: string]: any;
}

export const sendMail = async (
  steps: StepDefinition[],
  context: AssistantContext,
  additionalData: AdditionalData = {},
): Promise<void> => {
  const mailBody = getMarkdownData(steps, context);

  const mailAttachments = async (): Promise<string[]> => {
    try {
      return await getUploadedFiles(context);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(
        'An error occurred while reading Files-Upload from s3-bucket.',
      );
    }
  };

  try {
    await axios.post(process.env.VUE_APP_FUNCTIONS_URL + '/mail', {
      attachments: await mailAttachments(),
      data: mailBody,
      ...additionalData,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('An error occurred while sending the mail.');
  }
};
