import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import assistantIcons from '~/plugins/font-awesome';

// These are common icons in Vuetify, currently used in base-assistant
// If you add more Vuetify components, you may need to add more icons
// when you encounter console errors regarding missing icons
library.add({
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faInfoCircle,
  faSquare,
});

library.add(assistantIcons);

Vue.component('font-awesome-icon', FontAwesomeIcon);
