<script>
import { VSelect } from 'vuetify/lib';
export default VSelect.extend({
  props: {
    attach: {
      default: true,
      type: [Boolean, String],
    },
    dense: {
      default: true,
      type: [Boolean, String],
    },
    outlined: {
      default: true,
      type: [Boolean, String],
    },
  },
});
</script>
